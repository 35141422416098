import * as actionTypes from "@stores/actionTypes";

const initialGroupState = {
  categories: [],
  openGroup: {},
  openBill: {},
};

const GroupReducer = (groupState = initialGroupState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_GROUP_CATEGORY:
      return {
        ...groupState,
        categories: action.payload.categories,
      };

    case actionTypes.OPEN_GROUP:
      return {
        ...groupState,
        openGroup: action.payload.group,
      };

    case actionTypes.OPEN_BILL:
      return {
        ...groupState,
        openBill: action.payload.bill,
      };

    default:
      return groupState;
  }
};

export default GroupReducer;
