import React, { useState } from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import { theme, generateUsername } from "@utils/index";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "@stores/actions";
import { LoginValidationSchema } from "@pages/Schemas";
import {
  signIn,
  getCurrentuser,
  getPreferences,
  socialLogin,
} from "@api/index";
import { TextInput, Label, AuthButton, GoogleButton } from "@components/index";

const LoginPage = () => {
  const dispatcher = useDispatch();
  const [loginError, setLoginError] = useState(null);
  const [googling, SetGoogling] = useState({
    isLoading: false,
  });
  const [formState, setFormState] = useState({
    isLoading: false,
    email: "",
    password: "",
  });

  const HandleOnSignIn = async (values) => {
    try {
      values.username = generateUsername(values.email);
      setFormState({ ...formState, isLoading: true });
      const response = await signIn(values);
      const currentuserData = await getCurrentuser(response.token);
      const { user } = currentuserData;
      const loggedInSession = {
        email: user.email,
        username: user.username,
        token: user.token,
      };
      const userPreferences = await getPreferences(user.token);
      const { preferences } = userPreferences;
      dispatcher(actions.userPreferenceSaved(preferences));
      dispatcher(actions.userRegistered(user, true));
      dispatcher(actions.userSignedIn(loggedInSession, true));
      setFormState({ ...formState, isLoading: false });
    } catch (error) {
      setFormState({ ...formState, isLoading: false });
      let finalError = "Unknown sign In error occured";
      switch (error.response.status) {
        case 400:
          finalError = "validation error occured. Ensure all fields are filled";
          break;
        case 401:
          finalError = "authorization error occured. Invalid credentials";
          break;
        case 403:
          finalError = "access error occured. Access denied";
          break;
        default:
          if (
            error.response !== undefined &&
            error.response.data !== undefined
          ) {
            finalError = error.response.data.detail;
          }
          if (
            error.response !== undefined &&
            error.response.data !== undefined
          ) {
            finalError = error.response.data.detail;
          }
          break;
      }
      setLoginError(finalError);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginValidationSchema,
    onSubmit: (values) => HandleOnSignIn(values),
  });

  const handleGoogleLogin = async (googleResponse) => {
    SetGoogling({ ...googling, isLoading: true });
    const userInfo = googleResponse;
    const { tokenId } = userInfo;

    const formData = { auth_token: tokenId };
    const response = await socialLogin(formData);
    const currentuserData = await getCurrentuser(response.token);
    const { user } = currentuserData;
    const loggedInSession = {
      email: user.email,
      username: user.username,
      token: user.token,
    };
    const userPreferences = await getPreferences(response.token);
    const { preferences } = userPreferences;
    dispatcher(actions.userPreferenceSaved(preferences));
    dispatcher(actions.userRegistered(user, true));
    dispatcher(actions.userSignedIn(loggedInSession, true));
    SetGoogling({ ...googling, isLoading: false });
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FlexRow>
        <FlexColumn top wide={1}>
          {/* <Logo src={logo} /> */}
          <LoginTitle>Login</LoginTitle>
        </FlexColumn>
        <FlexColumnBottom wide={3}>
          <FlexRow>
            <FieldSet>
              <ErrorText>{loginError}</ErrorText>
            </FieldSet>

            <FieldSet>
              <Label title={"Email Address"} input={"email"} />
              <TextInput
                error={formik.errors.email}
                formik={formik}
                formikKey="email"
                type="email"
              />
            </FieldSet>
            <FieldSet>
              <Label title={"Password"} input={"password"} />
              <TextInput
                error={formik.errors.password}
                formik={formik}
                formikKey="password"
                type="password"
              />
            </FieldSet>
            <FieldSet right>
              <AccountText>
                <AccountLink href="/resets/request">{`Forgot Password `}</AccountLink>
              </AccountText>
            </FieldSet>
            <FieldSet>
              <AuthButton
                title={"Login"}
                loading={formState.isLoading}
                disabled={formState.isLoading}
              />
            </FieldSet>
            <FieldSet right>
              <AccountText>
                Don't have account?
                <AccountLink href="/register">{` Register here `}</AccountLink>
              </AccountText>
            </FieldSet>
            <FieldSet wide={2}>
              <GoogleButton
                handleGoogleLogin={(resp) => handleGoogleLogin(resp)}
                title={"Continue with Google"}
                disabled={googling.isLoading}
              />
            </FieldSet>
          </FlexRow>
        </FlexColumnBottom>
      </FlexRow>
    </Form>
  );
};

const Form = styled.form`
  background-color: ${theme.colors.none};
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 40%;
  }
  padding-bottom: 30px;
  padding-top: 30px;
  border-radius: 30px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FlexColumn = styled.div`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 20px;
  flex: ${(props) => props.wide};
  background-color: ${theme.colors.primary};
`;

const FlexColumnBottom = styled.div`
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 40px;
  flex: ${(props) => props.wide};
  border: solid 1px ${theme.colors.progressBarEmpty};
  background-color: ${theme.colors.white};
  text-align: left;
`;

const FieldSet = styled.div`
  flex: ${(props) => props.wide};
  text-align: ${(props) => (props.right ? "right" : "left")};
`;

const Logo = styled.img`
  margin-top: 20px;
  height: 50px;
  width: auto;
`;
const LoginTitle = styled.p`
  color: ${theme.colors.white};
  font-size: 32px;
  font-weight: bold;
  line-height: 30px;
`;

const AccountText = styled.p`
  color: ${theme.colors.black};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

const AccountLink = styled.a`
  color: ${theme.colors.primary};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

const ErrorText = styled.p`
  color: ${theme.colors.red};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

export { LoginPage };
