import theme from "@utils/theme";
import React from "react";
import styled from "styled-components";

const CheckInput = ({ type, formik, formikKey, disabled, error }) => {
  return (
    <div style={{ display: "block" }}>
      <Row>
        <LeftCol>
          <Input
            disabled={disabled}
            id={formikKey}
            name={formikKey}
            type={type}
            onChange={formik.handleChange}
            value={formik.values.formikKey}
          />
        </LeftCol>
        <RightCol>
          <StyledLabel>
            By Clicking Create Account you agree to our Terms and Conditions
          </StyledLabel>
        </RightCol>
      </Row>
      <p>
        <InputError>{error}</InputError>
      </p>
    </div>
  );
};

const Input = styled.input`
  padding: 10px 5px 5px 10px;
  border-radius: 40px;
  border: solid 2px ${theme.colors.progressBarEmpty};
  display: block;
  width: 100%;
  height: 28px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
`;
const Row = styled.div`
  flex-direction: row;
  display: flex;
`;

const LeftCol = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
`;

const RightCol = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 4;
`;

const StyledLabel = styled.p`
  display: block;
  width: 100%;
  color: ${theme.colors.textSecondary};
  font-size: 14px;
  font-weight: normal;
`;

const InputError = styled.p`
  display: block;
  width: 100%;
  color: ${theme.colors.red};
  font-size: 14px;
  font-weight: normal;
`;

export { CheckInput };
