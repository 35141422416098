import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import styled from "styled-components";
import { theme, Clean400Errormessage } from "@utils/index";
import { changePassword } from "@api/Auth";
import { ResetSchema } from "@pages/Schemas";
import { TextInput, Label, AuthButton, GoogleButton } from "@components/index";

const ResetPasswordPage = () => {
  const storedState = useSelector((state) => state);
  const { authentication } = storedState;
  const { pwdReset } = authentication;
  const [loginError, setLoginError] = useState(null);
  const [done, setDone] = useState(false);
  const [userEmail, SetUserEmail] = useState(pwdReset.email);

  const [formState, SetFormState] = useState({
    isLoading: false,
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
      email: userEmail,
    },
    validationSchema: ResetSchema,
    onSubmit: (values) => HandleOnResetPwd(values),
  });

  const HandleOnResetPwd = (values) => {
    SetFormState({ ...formState, isLoading: true });
    const PostData = {
      new_password: values.password,
      confirm_password: values.confirm_password,
      email: userEmail,
    };
    changePassword(PostData)
      .then((resp) => {
        SetFormState({ ...formState, isLoading: false });
        setDone(true);
      })
      .catch((error) => {
        console.log("error", error.response);
        const cleanedError = Clean400Errormessage(
          JSON.stringify(error.response.data)
        );
        SetFormState({ ...formState, isLoading: false });
        if (error.response !== undefined && error.response.status === 400) {
          setLoginError(cleanedError);
        } else {
          setLoginError(
            `Password reset failed. Ensure password is atleast 8 letters long`
          );
        }
      });
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FlexRow>
        <FlexColumn top wide={1}>
          <LoginTitle>Change Password</LoginTitle>
        </FlexColumn>
        <FlexColumnBottom wide={3}>
          <FlexRow>
            <FieldSet>
              {done === true && (
                <SuccessText>Password reset successfully</SuccessText>
              )}
              <ErrorText>{loginError}</ErrorText>
            </FieldSet>

            <FieldSet>
              <Label title={"New Password"} input={"password"} />
              <TextInput
                error={formik.errors.password}
                formik={formik}
                formikKey="password"
                type="password"
              />
            </FieldSet>

            <FieldSet>
              <Label title={"Confirm password"} input={"confirm_password"} />
              <TextInput
                error={formik.errors.confirm_password}
                formik={formik}
                formikKey="confirm_password"
                type="password"
              />
            </FieldSet>

            <FieldSet right>
              <AccountText>
                {done === true ? (
                  <AccountLink href="/">{`Proceed to Login `}</AccountLink>
                ) : (
                  <AccountLink href="/">{` Back to Homepage `}</AccountLink>
                )}
              </AccountText>
            </FieldSet>
            <FieldSet>
              <AuthButton
                loading={formState.isLoading}
                disabled={formState.isLoading}
                title={"Reset Password"}
              />
            </FieldSet>
          </FlexRow>
        </FlexColumnBottom>
      </FlexRow>
    </Form>
  );
};

const Form = styled.form`
  background-color: ${theme.colors.none};
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 40%;
  }
  padding-bottom: 30px;
  padding-top: 30px;
  border-radius: 30px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FlexColumn = styled.div`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 20px;
  flex: ${(props) => props.wide};
  background-color: ${theme.colors.primary};
`;

const FlexColumnBottom = styled.div`
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 40px;
  flex: ${(props) => props.wide};
  border: solid 1px ${theme.colors.progressBarEmpty};
  background-color: ${theme.colors.white};
  text-align: left;
`;

const FieldSet = styled.div`
  flex: ${(props) => props.wide};
  text-align: ${(props) => (props.right ? "right" : "left")};
`;

const Logo = styled.img`
  margin-top: 20px;
  height: 50px;
  width: auto;
`;
const LoginTitle = styled.p`
  color: ${theme.colors.white};
  font-size: 32px;
  font-weight: bold;
  line-height: 30px;
`;

const AccountText = styled.p`
  color: ${theme.colors.black};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

const AccountLink = styled.a`
  color: ${theme.colors.primary};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

const ErrorText = styled.p`
  color: ${theme.colors.red};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

const SuccessText = styled.p`
  color: ${theme.colors.green};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

export { ResetPasswordPage };
