import React, { useState, useEffect } from "react";
import { Card, CardHeader, Table, Container, Row, Col } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router-dom";
import Header from "components/Headers/Header.js";
import { theme } from "@utils";
import { getMyGroups } from "@api/index";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "@stores/actions";
import styled from "styled-components";

const Index = (props) => {
  const history = useHistory();
  const dispatcher = useDispatch();
  const [groups, setGroups] = useState({ loading: false, results: [] });
  const storedState = useSelector((state) => state);
  const { user } = storedState.authentication;
  const { token } = user;

  const findMyGroups = () => {
    setGroups({ ...groups, loading: true });
    getMyGroups(token)
      .then((response) => {
        const { results } = response;
        setGroups({ ...groups, results, loading: false });
      })
      .catch((error) => {
        setGroups({ ...groups, loading: false });
        alert("Groups error", `Error occured ${String(error)}`);
      });
  };

  useEffect(() => {
    findMyGroups();
    return () => undefined;
  }, []);

  const HandleOnViewBills = (group) => {
    dispatcher(actions.openGroup(group));
    const goto = "/admin/bills";
    history.push(goto);
  };

  const HandleOnViewMembers = (group) => {
    dispatcher(actions.openGroup(group));
    const goto = "/admin/members";
    history.push(goto);
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">My groups</h3>
                    <small style={{ fontSize: 12 }} className="text-warning">
                      Select group to see bills and payments
                    </small>
                  </div>
                  <div className="col text-right">
                    {/* <Button color="warning" href="/groups" size="sm">
                      View All
                    </Button> */}
                  </div>
                </Row>
              </CardHeader>
              {groups.loading ? (
                <p style={{ margin: 10 }}>
                  <Skeleton height={30} count={3} />
                </p>
              ) : (
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ textAlign: "left" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Category</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups.results.map((group, index) => (
                      <tr key={index}>
                        <td>{group.id}</td>
                        <td>{String(group.name).toLocaleUpperCase()}</td>
                        <td>{group.email}</td>
                        <td>{group.phone}</td>
                        <td>{group.category}</td>
                        <td onClick={() => undefined}>
                          <SpanLink onClick={() => HandleOnViewBills(group)}>
                            <i className="fas fa-external-link text-success mr-3" />
                            <u>View bills</u>
                          </SpanLink>

                          <SpanLink onClick={() => HandleOnViewMembers(group)}>
                            <i className="fas fa-external-link text-success mr-3" />
                            <u>View members</u>
                          </SpanLink>
                        </td>
                      </tr>
                    ))}

                    {groups.results.length === 0 && (
                      <tr>
                        <th colSpan={4} scope="row">
                          You do not have any groups to view payments
                        </th>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </Card>
          </Col>

          <Col xl="12">
            <br></br>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const SpanLink = styled.span`
  cursor: pointer;
  color: ${theme.colors.primary};
`;

export default Index;
