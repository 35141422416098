import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { getMyGroups, getGroupBills } from "@api/index";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import * as actions from "@stores/actions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { LoadingIndicator } from "@components/index";
import Header from "@components/Headers/Header";
import { theme, ToMoney } from "@utils/index";

const BillsView = () => {
  const history = useHistory();
  const dispatcher = useDispatch();
  const [groups, setGroups] = useState({ loading: false, results: [] });
  const storedState = useSelector((state) => state);
  const { user } = storedState.authentication;
  const { openGroup } = storedState.group;
  const { token } = user;
  const [selectedGroup, setSelectedGroup] = useState(openGroup.id);
  const [bills, setBills] = useState({ loading: false, results: [] });

  const findMyGroups = () => {
    setGroups({ ...groups, loading: true });
    getMyGroups(token)
      .then((response) => {
        const { results } = response;
        setGroups({ ...groups, results, loading: false });
      })
      .catch((error) => {
        setGroups({ ...groups, loading: false });
        alert("Groups error", `Error occured ${String(error)}`);
      });
  };

  useEffect(() => {
    async function loadDefault() {
      findMyGroups();
      if (openGroup.id !== null && openGroup.id !== undefined) {
        await findGroupBills(openGroup.id);
      }
    }
    loadDefault();
    return () => undefined;
  }, []);

  const setOptionValue = async (e) => {
    const groupChose = e.target.value;
    setSelectedGroup(groupChose);
    await findGroupBills(groupChose);
  };

  const findGroupBills = async (group_id) => {
    setBills({ ...bills, loading: true });
    try {
      const bills_data = await getGroupBills(group_id, token, 1);
      // console.log("bills_data", bills_data);
      const { results } = bills_data;
      setBills({ ...bills, loading: false, results });
      return;
    } catch (error) {
      setBills({ ...bills, loading: false });
      return;
    }
  };

  const OnBillClicked = (bill) => {
    dispatcher(actions.openBill(bill));
    const goto = "/admin/transactions";
    history.push(goto);
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        className="mt--7"
        style={{ color: theme.colors.textPrimary }}
        fluid
      >
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="justify-content-center">
                  <Col md={2}>
                    <p>Select group</p>
                  </Col>
                  <Col md={4}>
                    <SelectBox
                      disabled={groups.loading}
                      defaultValue={selectedGroup}
                      onChange={setOptionValue}
                    >
                      <option value={null}>Select group</option>
                      {groups.results.map((grp, index) => {
                        if (grp.id === openGroup.id) {
                          return (
                            <option selected value={grp.id} key={index}>
                              {grp.name}
                            </option>
                          );
                        } else {
                          return (
                            <option value={grp.id} key={index}>
                              {grp.name}
                            </option>
                          );
                        }
                      })}
                    </SelectBox>
                  </Col>
                </Row>
                <br></br>
                <h3 className="mb-0">Available bills for selected group</h3>
              </CardHeader>
              {bills.loading || groups.loading ? (
                <p style={{ margin: 10 }}>
                  <Skeleton height={30} count={5} />
                </p>
              ) : (
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ textAlign: "left" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#ID</th>
                      <th scope="col">Group</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Due date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bills.results.map((bill, index) => (
                      <tr>
                        <th scope="row">{bill.id}</th>
                        <th>{bill.narration}</th>
                        <td>{`$${ToMoney(bill.amount)}`}</td>
                        <td>
                          <i className="fas fa-calendar text-success mr-3" />
                          {bill.due_date}
                        </td>
                        <td onClick={() => OnBillClicked(bill)}>
                          <SpanLink>
                            <i className="fas fa-external-link text-success mr-3" />
                            <u>View transactions</u>
                          </SpanLink>
                        </td>
                      </tr>
                    ))}
                    {bills.loading && (
                      <tr style={{ textAlign: "center" }}>
                        <th colSpan={4} scope="row">
                          <LoadingIndicator
                            type={"spin"}
                            color={theme.colors.black}
                          />
                        </th>
                      </tr>
                    )}
                    {bills.results.length === 0 && (
                      <tr>
                        <th colSpan={4} scope="row">
                          This group does not have any bills yet
                        </th>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}

              <CardFooter className="py-4">
                <nav aria-label="..."></nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        <br></br>
      </Container>
    </>
  );
};

const SelectBox = styled.select`
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  font-size: 14px;
  width: 200px;
`;

const SpanLink = styled.span`
  cursor: pointer;
  color: ${theme.colors.primary};
`;

export default BillsView;
