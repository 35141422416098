import Index from "views/Index.js";
import Profile from "@views/Profile";
import BillsView from "@views/Bills";
import MembersView from "views/Members";
import TransactionsView from "@views/TransactionsView";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-red",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/members",
    name: "Members Reports",
    icon: "ni ni-tv-2 text-red",
    component: MembersView,
    layout: "/admin",
  },
  {
    path: "/bills",
    name: "Bills",
    icon: "ni ni-box-2 text-red",
    component: BillsView,
    layout: "/admin",
  },
  {
    path: "/transactions",
    name: "Transactions",
    icon: "ni ni-chart-pie-35 text-red",
    component: TransactionsView,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "My Profile",
    icon: "ni ni-single-02 text-red",
    component: Profile,
    layout: "/admin",
  },
];
export default routes;
