const theme = {
  colors: {
    primary: '#98163E',
    primaryLight: '#F5E1E7',
    primaryFaint: '#e9c8d2',
    primaryDark: '#8E0B32',
    mediumBlack: '#151514',
    success: '#5DD136',
    danger: '#ED4835',
    textPrimary: '#2D2D2F',
    textSecondary: '#8D8D8E',
    textBlack: '#15141F',
    textWhite: '#FFFFFF',
    textGrey: '#A2A0A8',
    textDarkGrey: '#9CA3AF',
    modalTextBlack: '#262628',
    modalTextGrey: '#9B9B9B',
    none: 'transparent',
    backgroundGrey: '#F9F9FA',
    backgroundColor: '#F6F6F6',
    backgroundLight: '#FFFFFF',
    white: '#FFFFFF',
    background: '#FCFDFF',
    progressBarEmpty: '#ECECEC',
    lightButtonBackground: '#F5F7FE',
    black: '#15141F',
    green: 'green',
    faintGreen: '#00873fb8',
    actionButton: '#98163E',
    tabStrokeColor: '#E9E9E9',
    sizeBox: '#e5e2e2',
    checkBox: '#DCDBE0',
    border: '#e6e6e7',
    indicator: '#CECECE',
    red: '#cc0000',
    bronze: '#e9884c',
    silver: '#c0c0c0',
    gold: '#F6A609',
    goldLight: '#fff3de',
    grey: '#efefef',
    lightGrey: '#e8e8e8',
    darkGrey: '#c4c4c4',
    darkBlack: '#111111',
    mediumGrey: '#D0D0D0',
    checkoutRowBg: '#e6e6e8',
    placeHolderText: '#a6a6aa',
    bronzeTier: '#D8835E',
    silverTier: '#2D2D2F',
    goldTier: '#F9C364',
    platinumTier: '#D3D6D8',
    warn: '#d8a50c'
  },
  images: {
    splashLogo: 'splash.png',
    darkLogo: 'dark_logo.png',
    lightLogo: 'light_logo.png',
    screenBackgroundImage: 'background.jpg'
  }
};

export default theme;
