import axios from "axios";
import { SERVER_API } from "@utils/index";

const API = axios.create({
  baseURL: SERVER_API,
});

export const signIn = (formData) =>
  API.post("/auth/login", formData).then((res) => res.data);

export const signUp = (formData) =>
  API.post("/auth/register", formData).then((res) => res.data);

export const updateUser = (id, formData, bearerToken) =>
  API.put("/auth/user/update/" + id, formData, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const getCurrentuser = (bearerToken) =>
  API.get("/auth/current/user", {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const getuserById = (id, bearerToken) =>
  API.get("/auth/user/user/" + id, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const updatePasswordInApp = (formData, bearerToken) =>
  API.put("/auth/change/password/in/app", formData, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const requestResetPwd = (formData) =>
  API.post("/auth/reset/password/request", formData).then((res) => res.data);

export const verifyOtp = (formData) =>
  API.post("/auth/reset/verify/otp", formData).then((res) => res.data);

export const changePassword = (formData) =>
  API.put("/auth/reset/change/password", formData).then((res) => res.data);
