import * as actionTypes from '@stores/actionTypes';

const initialTutorialState = {
  initialLoad: false,
  skippedTutorial: false
};

const PreauthReducer = (tutorialState = initialTutorialState, action) => {
  switch (action.type) {
    case actionTypes.APP_LOADED:
      return {
        ...tutorialState,
        initialLoad: action.payload.status
      };
    case actionTypes.TUTORIAL_SKIPPED:
      return {
        ...tutorialState,
        skippedTutorial: action.payload.status,
        initialLoad:
          action.payload.status /** skipping means app loaded anyway */
      };
    default:
      return tutorialState;
  }
};

export default PreauthReducer;
