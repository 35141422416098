import axios from 'axios';
import { SERVER_API } from '@utils/index';

const API = axios.create({
  baseURL: SERVER_API
});

export const postEvent = (formData, bearerToken) =>
  API.post('/events/create', formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const updateEvent = (id, formData, bearerToken) =>
  API.put('/events/event/update/' + id, formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const getMyEvents = (bearerToken) =>
  API.get('/events/list/by/user', {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const getNetworkEvents = (bearerToken, page) =>
  API.get(`/events/find/related?search=tag&page=${page}&count=10`, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const searchNetworkEvents = (bearerToken, page, search) =>
  API.get(`/events/find/related?search=${search}&page=${page}&count=10`, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const rsvpEvent = (formData, bearerToken) =>
  API.post('/events/event/rsvp', formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const getGuests = (event, page, bearerToken) =>
  API.get(
    `/events/event/find/attendance?event_id=${event}&ordering=-created_at&count=10&page=${page}`,
    {
      headers: { Authorization: `Bearer ${bearerToken}` }
    }
  ).then((res) => res.data);
