import axios from 'axios';
import { SERVER_API } from '@utils/index';

const API = axios.create({
  baseURL: SERVER_API
});

export const getPreferences = (bearerToken) =>
  API.get(`/preferences/user/find`, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const updateUserPreferences = (id, formData, bearerToken) =>
  API.put(`/preferences/user/preference/update/${id}`, formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const updateUserDeviceToken = (id, formData, bearerToken) =>
  API.put(`/preferences/user/preferences/user/device-token/${id}`, formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const getFaq = (bearerToken) =>
  API.get(`/preferences/user/others/faq`, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const getContacts = (bearerToken) =>
  API.get(`/preferences/user/others/contacts`, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);
