import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Card, CardHeader, CardFooter, Container, Row, Col } from "reactstrap";
import { getPaymentsOnABill, getGroupBills } from "@api/index";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { LoadingIndicator } from "@components/index";
import Header from "@components/Headers/Header";
import { theme, ToMoney } from "@utils/index";

const TransactionsView = () => {
  const storedState = useSelector((state) => state);
  const { user } = storedState.authentication;
  const { openGroup, openBill } = storedState.group;
  const { token } = user;
  const [selectedBill, setSelectedBill] = useState(openBill.id);
  const [bills, setBills] = useState({ loading: false, results: [] });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const columns = [
    {
      name: "#Ref",
      selector: (row) => row.trxn_payment_ref,
      cellExport: (row) => row.trxn_payment_ref,
    },
    {
      name: "Group",
      selector: (row) => row.group_label,
      cellExport: (row) => row.group_label,
    },
    {
      name: "Narration",
      selector: (row) => row.narration,
      cellExport: (row) => row.narration,
    },
    {
      name: "Member",
      selector: (row) => `${row.user_full_name}(${row.member_number})`,
      cellExport: (row) => `${row.user_full_name}(${row.member_number})`,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => `$${ToMoney(row.amount)}`,
      cellExport: (row) => ToMoney(row.amount),
      sortable: true,
    },
    {
      name: "Payment date",
      selector: (row) => row.created_at,
      cellExport: (row) => row.created_at,
      sortable: true,
    },
  ];
  const fetchTransactions = async (page) => {
    setLoading(true);
    try {
      const trxns_data = await getPaymentsOnABill(
        token,
        selectedBill,
        page,
        perPage
      );
      const { results, count } = trxns_data;
      setData(results);
      setTotalRows(count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Erro", error.message);
    }
  };

  const handlePageChange = (page) => {
    fetchTransactions(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const trxns_data = await getPaymentsOnABill(
        token,
        selectedBill,
        page,
        perPage
      );
      const { results } = trxns_data;
      setData(results);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Erro", error.message);
    }
  };

  useEffect(() => {
    async function loadDefault() {
      await findGroupBills(openGroup.id);
      if (selectedBill !== null && selectedBill !== undefined) {
        await fetchTransactions(1); // start with page 1
      }
    }
    loadDefault();
    return () => undefined;
  }, [selectedBill]);

  const setOptionValue = async (e) => {
    const billChose = e.target.value;
    setSelectedBill(billChose);
  };

  const findGroupBills = async (group_id) => {
    setBills({ ...bills, loading: true });
    try {
      const bills_data = await getGroupBills(group_id, token, 1);
      const { results } = bills_data;
      setBills({ ...bills, loading: false, results });
      return;
    } catch (error) {
      setBills({ ...bills, loading: false });
      return;
    }
  };
  const tableData = {
    columns,
    data,
  };
  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        className="mt--7"
        fluid
        style={{ color: theme.colors.textPrimary }}
      >
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="justify-content-center">
                  <Col md={2}>
                    <p>Select a bill</p>
                  </Col>
                  <Col md={4}>
                    <SelectBox
                      disabled={bills.loading}
                      defaultValue={selectedBill}
                      onChange={setOptionValue}
                    >
                      <option value={null}>Select a bill</option>
                      {bills.results.map((item, index) => {
                        if (item.id === selectedBill) {
                          return (
                            <option selected value={item.id} key={index}>
                              {item.narration}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item.id} key={index}>
                              {item.narration}
                            </option>
                          );
                        }
                      })}
                    </SelectBox>
                  </Col>
                </Row>
                <br></br>
              </CardHeader>
              <DataTableExtensions filter={false} print={false} {...tableData}>
                <DataTable
                  noHeader
                  columns={columns}
                  data={data}
                  progressPending={loading}
                  progressComponent={
                    <LoadingIndicator
                      type={"spin"}
                      color={theme.colors.black}
                    />
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </DataTableExtensions>

              <CardFooter className="py-4">
                <nav aria-label="..."></nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        <br></br>
      </Container>
    </>
  );
};

const SelectBox = styled.select`
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  font-size: 14px;
  width: 200px;
`;

export default TransactionsView;
