import axios from 'axios';
import { SERVER_API } from '@utils/index';

const API = axios.create({
  baseURL: SERVER_API
});

export const getRecentBills = (page, count, bearerToken) =>
  API.get(
    `/transactions/list/by/user/pending?dr_cr=DR&ordering=due_date&page=${page}&count=${count}`,
    {
      headers: { Authorization: `Bearer ${bearerToken}` }
    }
  ).then((res) => res.data);

export const getRecentBillsByGroup = (group, page, count, bearerToken) =>
  API.get(
    `/transactions/list/by/user/pending?dr_cr=DR&ordering=due_date&group=${group}&page=${page}&count=${count}`,
    {
      headers: { Authorization: `Bearer ${bearerToken}` }
    }
  ).then((res) => res.data);

export const getTransactions = (page, count, bearerToken) =>
  API.get(
    `/transactions/list/by/user/week/paid?dr_cr=CR&ordering=-id&page=${page}&count=${count}`,
    {
      headers: { Authorization: `Bearer ${bearerToken}` }
    }
  ).then((res) => res.data);

export const getMonthTransactions = (page, count, bearerToken) =>
  API.get(
    `/transactions/list/by/user/month/paid?dr_cr=CR&ordering=-id&page=${page}&count=${count}`,
    {
      headers: { Authorization: `Bearer ${bearerToken}` }
    }
  ).then((res) => res.data);

export const getYearTransactions = (page, count, bearerToken) =>
  API.get(
    `/transactions/list/by/user/year/paid?dr_cr=CR&ordering=-id&page=${page}&count=${count}`,
    {
      headers: { Authorization: `Bearer ${bearerToken}` }
    }
  ).then((res) => res.data);

export const getTransactionsStats = (bearerToken) =>
  API.get(`/transactions/get/stats`, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const getWalletBalance = (bearerToken) =>
  API.get(`/transactions/get/wallet`, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);
