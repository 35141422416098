import axios from "axios";
import { SERVER_API } from "@utils/index";

const API = axios.create({
  baseURL: SERVER_API,
});

export const getGroupCategories = (bearerToken) =>
  API.get("/groups/list/categories", {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const createGroup = (formData, bearerToken) =>
  API.post("/groups/create", formData, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const updateGroup = (id, formData, bearerToken) =>
  API.put("/groups/group/update/" + id, formData, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const getGroupById = (id, bearerToken) =>
  API.get(`/groups/group/${id}`, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const triggerStripeOnboardLink = (id, bearerToken) =>
  API.get(`/groups/stripe/onboard/${id}`, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const getMyGroups = (bearerToken) =>
  API.get("/groups/list/by/user", {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const getGroupMembers = (page, limit, group, bearerToken) =>
  API.get(`/groups/list/members/${group}?page=${page}&count=${limit}`, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const searchGroupMembers = (page, limit, group, bearerToken, search) => {
  if (search === null || search === undefined) {
    return API.get(
      `/groups/list/members/${group}?page=${page}&count=${limit}`,
      {
        headers: { Authorization: `Bearer ${bearerToken}` },
      }
    ).then((res) => res.data);
  }
  return API.get(
    `/groups/list/members/${group}?page=${page}&count=${limit}&search=${search}`,
    {
      headers: { Authorization: `Bearer ${bearerToken}` },
    }
  ).then((res) => res.data);
};

export const getGroupAdmins = (group, bearerToken) =>
  API.get("/groups/list/admins/" + group + "?page=1&count=5", {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const getGroupOfficials = (group, bearerToken) =>
  API.get("/groups/members/officials/list/" + group + "?page=1&count=5", {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const getGroupStatements = (page, count, group, bearerToken) =>
  API.get(
    `/transactions/find?group=${group}&page=${page}&count=${count}&dr_cr=CR`,
    {
      headers: { Authorization: `Bearer ${bearerToken}` },
    }
  ).then((res) => res.data);

export const getPaymentsOnABill = (bearerToken, bill, page, count) =>
  API.get(
    `/transactions/find?trxn_item=${bill}&page=${page}&count=${count}&dr_cr=CR&payment_status=PAID&is_bill=1`,
    {
      headers: { Authorization: `Bearer ${bearerToken}` },
    }
  ).then((res) => res.data);

export const searchGroupStatements = (
  page,
  count,
  group,
  bearerToken,
  search
) => {
  if (search === null || search === undefined) {
    return API.get(
      `/transactions/find?group=${group}&page=${page}&count=${count}&dr_cr=CR`,
      {
        headers: { Authorization: `Bearer ${bearerToken}` },
      }
    ).then((res) => res.data);
  }
  return API.get(
    `/transactions/find?group=${group}&page=${page}&count=${count}&dr_cr=CR&search=${search}`,
    {
      headers: { Authorization: `Bearer ${bearerToken}` },
    }
  ).then((res) => res.data);
};

export const inviteMember = (formData, bearerToken) =>
  API.post("/groups/members/invitations/add", formData, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const getGroupInvitations = (group, bearerToken, page) =>
  API.get(
    `/groups/members/invitations/find?group=${group}&page=${page}&count=10`,
    {
      headers: { Authorization: `Bearer ${bearerToken}` },
    }
  ).then((res) => res.data);

export const searchGroupInvitate = (group, invitee_email, bearerToken) =>
  API.get(
    `/groups/members/invitations/find?group=${group}&invitee_email=${invitee_email}&page=1&count=1`,
    {
      headers: { Authorization: `Bearer ${bearerToken}` },
    }
  ).then((res) => res.data);

export const getMemberInvitations = (bearerToken) => {
  return API.get("/groups/members/invitations/by/user", {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);
};

export const acceptInvite = (inviteId, formData, bearerToken) =>
  API.put("/groups/members/invitations/accept/" + inviteId, formData, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const getGroupBills = (group, bearerToken, page, count = 1000) =>
  API.get(`/bills/find?group=${group}&page=${page}&count=${count}`, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const checkGroupStripeSetup = (formData, bearerToken) =>
  API.post("/groups/stripe/check/setup", formData, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const UpdateGroupMember = (id, formData, bearerToken) =>
  API.put(`/groups/admins/update/${id}`, formData, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const DropGroupMember = (id, bearerToken) =>
  API.delete(`/groups/members/destroy/${id}`, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const AddGroupOfficial = (formData, bearerToken) =>
  API.post("/groups/members/officials/add", formData, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const AdminToOfficial = (formData, bearerToken) =>
  API.post("/groups/members/admin/promote", formData, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);

export const DropGroupOfficial = (id, bearerToken) =>
  API.delete(`/groups/members/officials/drop/${id}`, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  }).then((res) => res.data);
