import React from "react";
import Moment from "react-moment";

export const normalize = (number: number, factor = 1) => number;

export const hasMoreWords = (string) => string.split(" ").length > 1;

export const generateUsername = (email) =>
  email.split("@")[0] + Math.round(Math.random() * 10000);

export const greetingsHandler = () => {
  const date = new Date();
  const hour = date.getHours();
  if (hour <= 11) {
    return "Good Morning";
  }
  if (hour < 16) {
    return "Good Afternoon";
  }
  return "Good Evening";
};

export const dateFormat = (date) => <Moment date={date} format={"MMM Do"} />;
export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const HandleDropNull = (obj) => {
  Object.keys(obj).forEach(
    (k) => !obj[k] && obj[k] !== undefined && delete obj[k]
  );
  return obj;
};

export const ToPercent = (val) => parseFloat(Number(val) / 100);

export const ToMoney = (val) => parseFloat(Number(val) / 100).toFixed(2);

export const Clean400Errormessage = (error) => {
  if (error === undefined) {
    return "unknown error";
  }
  let string = String(error);
  string = string.replace("{", "");
  string = string.replace('":', " ");
  string = string.replace("}", "");
  string = string.replace("[", "");
  string = string.replace("]", "");
  string = string.replace('"', "");
  return string;
};
