import React, { useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import styled from "styled-components";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import { verifyOtp } from "@api/index";
import theme from "@utils/theme";
import { AuthButton } from "@components/index";

const VerifyOtpPage = () => {
  const navigate = useHistory();
  const storedState = useSelector((state) => state);
  const { authentication } = storedState;
  const { pwdReset } = authentication;

  const [loginError, setLoginError] = useState(null);
  const [userEmail, SetUserEmail] = useState(pwdReset.email);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  const formik = useFormik({
    initialValues: {
      otp: 0,
    },
    onSubmit: (values) => HandleOnCodeVerify(values.otp),
  });

  const handleChange = (newOtpVal) => formik.setValues({ otp: newOtpVal });

  const HandleOnCodeVerify = (otp) => {
    setLoading(true);
    verifyOtp({ otp, email: userEmail })
      .then((resp) => {
        setLoading(false);
        navigate.push("/resets/reset");
      })
      .catch((error) => {
        setLoading(false);
        setLoginError(`Account Recovery failed. Invalid or expired OTP`);
      });
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FlexRow>
        <FlexColumn top wide={1}>
          <LoginTitle>Verify OTP</LoginTitle>
        </FlexColumn>
        <FlexColumnBottom wide={3}>
          <FlexRow>
            <FieldSet wide={2}>
              <AccountText>
                A one-time password has been sent to <b>{userEmail}</b>
              </AccountText>
            </FieldSet>
            <FieldSet>
              <ErrorText>{loginError}</ErrorText>
            </FieldSet>
            <FieldSet wide={2}>
              <OtpInput
                containerStyle={{ margin: 20, alignSelf: "center" }}
                inputStyle={{
                  padding: 10,
                  borderRadius: 20,
                  borderColor: theme.colors.progressBarEmpty,
                  width: 40,
                }}
                isDisabled={false}
                value={formik.values.otp}
                onChange={handleChange}
                numInputs={4}
                separator={<span>-</span>}
              />
            </FieldSet>

            <FieldSet>
              <AuthButton
                title={"Verify OTP"}
                loading={loading}
                disabled={loading || String(formik.values.otp).length !== 4}
              />
            </FieldSet>

            <FieldSet right>
              <AccountText>
                <AccountLink href="/">{` Back to Homepage `}</AccountLink>
              </AccountText>
            </FieldSet>
          </FlexRow>
        </FlexColumnBottom>
      </FlexRow>
    </Form>
  );
};

const Form = styled.form`
  background-color: ${theme.colors.none};
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 40%;
  }
  padding-bottom: 30px;
  padding-top: 30px;
  border-radius: 30px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FlexColumn = styled.div`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 20px;
  flex: ${(props) => props.wide};
  background-color: ${theme.colors.primary};
`;

const FlexColumnBottom = styled.div`
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 40px;
  flex: ${(props) => props.wide};
  border: solid 1px ${theme.colors.progressBarEmpty};
  background-color: ${theme.colors.white};
  text-align: left;
`;

const FieldSet = styled.div`
  flex: ${(props) => props.wide};
  display: flex;
  text-align: ${(props) => (props.right ? "right" : "left")};
`;

const Logo = styled.img`
  margin-top: 20px;
  height: 50px;
  width: auto;
`;
const LoginTitle = styled.p`
  color: ${theme.colors.white};
  font-size: 32px;
  font-weight: bold;
  line-height: 30px;
`;

const AccountText = styled.p`
  color: ${theme.colors.black};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

const AccountLink = styled.a`
  color: ${theme.colors.primary};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

const ErrorText = styled.p`
  color: ${theme.colors.red};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

export { VerifyOtpPage };
