import axios from 'axios';
import { SERVER_API } from '@utils/index';

const API = axios.create({
  baseURL: SERVER_API
});

export const getMessages = (page, count, bearerToken) =>
  API.get(`/auth/user/messages/find?page=${page}&count=${count}`, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const markAsRead = (id, formData, bearerToken) =>
  API.put(`/auth/message/mark/${id}`, formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const getCountNewMessages = (bearerToken) =>
  API.get(`/auth/message/count/unread`, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);
