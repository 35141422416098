import * as yup from "yup";

const RegisterSchema = yup.object().shape({
  name: yup.string().required().label("First name").min(3).max(50),
  email: yup.string().label("Email").email().required().min(5).max(50),
  password: yup
    .string()
    .required()
    .label("Password")
    .min(8, "Too short. Use at least 8 characters")
    .max(255, "Try a shorter password."),
  confirm_password: yup
    .string()
    .required()
    .label("Confirm password")
    .test(
      "passwords-match",
      "Passwords must match",
      function checkPassword(value) {
        return this.parent.password === value;
      }
    ),
  accepted_terms: yup
    .bool()
    .oneOf([true], "Accept Terms & Conditions is required"),
});

export default RegisterSchema;
