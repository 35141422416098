import theme from "@utils/theme";
import React from "react";
import styled from "styled-components";

const Label = ({ title, input }) => {
  return <StyledLabel htmlFor={input}>{title}</StyledLabel>;
};

const StyledLabel = styled.label`
  color: ${theme.colors.textSecondary};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
`;

export { Label };
