import theme from "@utils/theme";
import React from "react";
import { GoogleLogin } from "react-google-login";
import styled from "styled-components";

const GoogleButton = ({ title, handleGoogleLogin, disabled }) => {
  const googleLogo = `${process.env.REACT_APP_PUBLIC_URL}/google.png`;
  const clientID = process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID;
  const handleGoogleError = (errr) => console.log("errr", errr);
  return (
    <GoogleLogin
      clientId={clientID}
      render={(renderProps) => (
        <StyledButton
          onClick={renderProps.onClick}
          disabled={disabled === true}
        >
          <Image src={googleLogo} />
          {title}
        </StyledButton>
      )}
      onSuccess={handleGoogleLogin}
      onFailure={handleGoogleError}
      cookiePolicy={"single_host_origin"}
    />
  );
};

const Image = styled.img`
  width: 30px;
  height: 30px;
  margin: 5px;
`;

const StyledButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 40px;
  border: solid 2px ${theme.colors.progressBarEmpty};
  background-color: ${(props) =>
    props.disabled ? theme.colors.progressBarEmpty : theme.colors.white};
  color: ${theme.colors.textSecondary};
  font-size: 18px;
  font-weight: normal;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export { GoogleButton };
