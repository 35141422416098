import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

const UserHeader = () => {
  const storedState = useSelector((state) => state);
  const { user } = storedState.authentication;
  const { first_name, last_name, profile_pic } = user;
  const fullName = `${first_name} ${last_name}`;

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-5 d-flex align-items-center"
        style={{
          minHeight: "200px",
          backgroundImage: "url(" + profile_pic + ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="12">
              <h1 className="display-2 text-white">Hello {fullName}</h1>
              <p className="text-white mt-0 mb-5">
                This is your profile page. You can see the current information
                and also have an opportunity to make changes.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
