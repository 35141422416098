import { combineReducers } from 'redux';
import AuthenticationReducer from './Authentication';
import PreauthReducer from './Preauth';
import GroupReducer from './Group';

const reducers = combineReducers({
  preauth: PreauthReducer,
  authentication: AuthenticationReducer,
  group: GroupReducer
});

export default reducers;
