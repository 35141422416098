import axios from "axios";
import { SERVER_API } from "@utils/index";

const API = axios.create({
  baseURL: SERVER_API,
});

const socialLogin = (formData) =>
  API.post("/social/auth/google", formData).then((res) => res.data);

const nextCallHere = () => null;

export { socialLogin, nextCallHere };
