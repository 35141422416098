import axios from 'axios';
import { SERVER_API } from '@utils/index';

const API = axios.create({
  baseURL: SERVER_API
});

export const getPaymentRates = (bearerToken) =>
  API.get('/payments/rates/list', {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const addCardToUser = (formData, bearerToken) =>
  API.post('/pay/methods/card/create', formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const getMyCards = (bearerToken) =>
  API.get('/pay/methods/card/list/by/user', {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const deleteCardCard = (id, bearerToken) =>
  API.delete('/pay/methods/card/destroy/' + id, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const buildLinkToken = (formData, bearerToken) =>
  API.post('/pay/methods/account/generate/link-token', formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const createBankSource = (formData, bearerToken) =>
  API.post('/pay/methods/account/generate/plaid-source', formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const getMyAccounts = (bearerToken) =>
  API.get('/pay/methods/account/list/by/user', {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const deleteBankAccount = (id, bearerToken) =>
  API.delete('/pay/methods/account/destroy/' + id, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const billTrxnStripeCharge = (formData, bearerToken) =>
  API.post('/payments/stripe/bill/charge/create', formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const billTrxnWalletCharge = (formData, bearerToken) =>
  API.post('/payments/wallet/bill/charge/create', formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const eventTrxnStripeCharge = (formData, bearerToken) =>
  API.post('/payments/stripe/event/charge/create', formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const eventTrxnWalletCharge = (formData, bearerToken) =>
  API.post('/payments/wallet/event/charge/create', formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const walletTopUpStripeCharge = (formData, bearerToken) =>
  API.post('/payments/stripe/topup/charge/create', formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);
