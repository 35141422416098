import theme from "@utils/theme";
import React from "react";
import styled from "styled-components";
import { Input } from "reactstrap";

const TextInput = ({ type, formik, formikKey, disabled, error }) => {
  return (
    <>
      <NiceInput
        disabled={disabled}
        id={formikKey}
        name={formikKey}
        type={type}
        onChange={formik.handleChange}
        value={formik.values.formikKey}
      />
      <InputError>{error}</InputError>
    </>
  );
};

const NiceInput = styled(Input)`
  border-radius: 40px;
  border: solid 2px ${theme.colors.progressBarEmpty};
`;

const InputError = styled.p`
  display: block;
  width: 100%;
  color: ${theme.colors.red};
  font-size: 14px;
  font-weight: normal;
`;

export { TextInput };
