import * as actionTypes from "@stores/actionTypes";

export const userRegistered = (user, status) => ({
  type: actionTypes.USER_REGISTERED,
  payload: {
    user,
    status,
  },
});

export const userSignedIn = (session, status) => ({
  type: actionTypes.USER_SIGNED_IN,
  payload: {
    session,
    status,
  },
});

export const userPreferenceSaved = (preferences) => ({
  type: actionTypes.USER_PREFERENCE_SAVED,
  payload: { preferences },
});

export const resettingPwdEmail = (email) => ({
  type: actionTypes.RESET_PWD_EMAIL,
  payload: { email },
});

export const userTokenRefreshed = (accessToken, refreshToken, expiresAt) => ({
  type: actionTypes.USER_TOKEN_REFRESHED,
  payload: {
    accessToken,
    refreshToken,
    expiresAt,
  },
});

export const enabledBiometrics = (hasFace, hasFinger) => ({
  type: actionTypes.ENABLED_BIOMETRICS,
  payload: {
    hasFace,
    hasFinger,
  },
});

export const cancelledBiometricsRequest = (status) => ({
  type: actionTypes.CANCELLED_BIOMETRICS,
  payload: {
    status,
  },
});

export const clearUserStore = () => ({
  type: actionTypes.CLEAR_USER_STORE,
  payload: {},
});
