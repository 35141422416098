import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { getMyGroups, searchGroupMembers } from "@api/index";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import * as actions from "@stores/actions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { LoadingIndicator } from "@components/index";
import Header from "@components/Headers/Header";
import { theme, ToMoney } from "@utils/index";

const MembersView = () => {
  const history = useHistory();
  const dispatcher = useDispatch();
  const [groups, setGroups] = useState({ loading: false, results: [] });
  const storedState = useSelector((state) => state);
  const { user } = storedState.authentication;
  const { openGroup } = storedState.group;
  const { token } = user;
  const [selectedGroup, setSelectedGroup] = useState(openGroup.id);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const extractMemberId = (group, memberships) => {
    if (memberships === undefined) {
      return {};
    }
    return memberships.find((raw) => Number(raw.group_id) === Number(group));
  };

  const columns = [
    {
      name: "#Member no.",
      selector: (row) => {
        const memberships = extractMemberId(selectedGroup, row.memberships);
        return memberships.member_number;
      },
      cellExport: (row) => {
        const memberships = extractMemberId(selectedGroup, row.memberships);
        return memberships.member_number;
      },
    },
    {
      name: "Name",
      selector: (row) => `${row.last_name} ${row.first_name}`,
      cellExport: (row) => `${row.last_name} ${row.first_name}`,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      cellExport: (row) => row.email,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      cellExport: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => {
        const memberships = extractMemberId(selectedGroup, row.memberships);
        return memberships.is_admin ? "Admin" : "Member";
      },
      cellExport: (row) => {
        const memberships = extractMemberId(selectedGroup, row.memberships);
        return memberships.is_admin ? "Admin" : "Member";
      },
      sortable: true,
    },
  ];

  const findGroupMembers = async (page) => {
    setLoading(true);
    try {
      const members_data = await searchGroupMembers(
        page,
        perPage,
        selectedGroup,
        token,
        null
      );
      const { results, count } = members_data;
      setData(results);
      setTotalRows(count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Erro", error.message);
    }
  };

  const handlePageChange = (page) => {
    findGroupMembers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const members_data = await searchGroupMembers(
        page,
        perPage,
        selectedGroup,
        token,
        null
      );
      const { results } = members_data;
      setData(results);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Erro", error.message);
    }
  };

  useEffect(() => {
    async function loadDefault() {
      findMyGroups();
      if (selectedGroup !== null && selectedGroup !== undefined) {
        await findGroupMembers(1); // start with pg 1
      }
    }
    loadDefault();
    return () => undefined;
  }, []);

  const setOptionValue = async (e) => {
    const groupChose = e.target.value;
    setSelectedGroup(groupChose);
  };

  const findMyGroups = () => {
    setGroups({ ...groups, loading: true });
    getMyGroups(token)
      .then((response) => {
        const { results } = response;
        setGroups({ ...groups, results, loading: false });
      })
      .catch((error) => {
        setGroups({ ...groups, loading: false });
        alert("Groups error", `Error occured ${String(error)}`);
      });
  };

  const tableData = {
    columns,
    data,
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        className="mt--7"
        style={{ color: theme.colors.textPrimary }}
        fluid
      >
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="justify-content-center">
                  <Col md={2}>
                    <p>Select group</p>
                  </Col>
                  <Col md={4}>
                    <SelectBox
                      disabled={groups.loading}
                      defaultValue={selectedGroup}
                      onChange={setOptionValue}
                    >
                      <option value={null}>Select group</option>
                      {groups.results.map((grp, index) => {
                        if (grp.id === selectedGroup) {
                          return (
                            <option selected value={grp.id} key={index}>
                              {grp.name}
                            </option>
                          );
                        } else {
                          return (
                            <option value={grp.id} key={index}>
                              {grp.name}
                            </option>
                          );
                        }
                      })}
                    </SelectBox>
                  </Col>
                </Row>
                <br></br>
                <h3 className="mb-0">Members of this group</h3>
              </CardHeader>

              <DataTableExtensions filter={false} print={false} {...tableData}>
                <DataTable
                  noHeader
                  columns={columns}
                  data={data}
                  progressPending={loading}
                  progressComponent={
                    <LoadingIndicator
                      type={"spin"}
                      color={theme.colors.black}
                    />
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </DataTableExtensions>

              <CardFooter className="py-4">
                <nav aria-label="..."></nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        <br></br>
      </Container>
    </>
  );
};

const SelectBox = styled.select`
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  font-size: 14px;
  width: 200px;
`;

const SpanLink = styled.span`
  cursor: pointer;
  color: ${theme.colors.primary};
`;

export default MembersView;
