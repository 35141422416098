import * as actionTypes from "@stores/actionTypes";

export const saveGroupCategory = (categories) => ({
  type: actionTypes.SAVE_GROUP_CATEGORY,
  payload: { categories },
});

export const openGroup = (group) => ({
  type: actionTypes.OPEN_GROUP,
  payload: { group },
});

export const openBill = (bill) => ({
  type: actionTypes.OPEN_BILL,
  payload: { bill },
});
