import theme from "@utils/theme";
import React from "react";
import { LoadingIndicator } from "@components/index";
import styled from "styled-components";

const AuthButton = ({ title, disabled, loading }) => {
  return (
    <Button type="submit" disabled={disabled}>
      <span style={{ marginRight: 14 }}>{title}</span>
      {loading === true && (
        <LoadingIndicator type={"spin"} color={theme.colors.black} />
      )}
    </Button>
  );
};

const Button = styled.button`
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 40px;
  border: solid 2px
    ${(props) =>
      props.disabled ? theme.colors.progressBarEmpty : theme.colors.primary};
  background-color: ${(props) =>
    props.disabled ? theme.colors.progressBarEmpty : theme.colors.primary};
  color: ${theme.colors.white};
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export { AuthButton };
