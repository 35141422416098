import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  LoginPage,
  RegisterPage,
  RequestResetPage,
  VerifyOtpPage,
  ResetPasswordPage,
} from "@pages/index";
import { theme } from "@utils";

import Admin from "@layouts/Admin";

function App() {
  const storedState = useSelector((state) => state);
  const { isLoggedIn, isRegistered } = storedState.authentication;

  return (
    <AppWrapper>
      <AppContainer>
        <BrowserRouter>
          <Switch>
            {isLoggedIn && isRegistered && (
              <>
                <Route path="/admin" render={(props) => <Admin {...props} />} />
                <Redirect from="/" to="/admin/index" />
              </>
            )}

            {!isLoggedIn && !isRegistered && (
              <>
                <Route
                  path="/"
                  exact
                  render={(props) => <LoginPage {...props} />}
                />
                <Route
                  path="/register"
                  exact
                  render={(props) => <RegisterPage {...props} />}
                />
                <Route
                  path="/resets/request"
                  render={(props) => <RequestResetPage {...props} />}
                />
                <Route
                  path="/resets/verify-otp"
                  render={(props) => <VerifyOtpPage {...props} />}
                />
                <Route
                  path="/resets/reset"
                  render={(props) => <ResetPasswordPage {...props} />}
                />
              </>
            )}
          </Switch>
        </BrowserRouter>
      </AppContainer>
    </AppWrapper>
  );
}

export default App;

const AppWrapper = styled.div`
  text-align: center;
`;

const AppContainer = styled.div`
  background-color: ${theme.colors.backgroundColor};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
`;
