import axios from 'axios';
import { SERVER_API } from '@utils/index';

const API = axios.create({
  baseURL: SERVER_API
});

export const createBill = (formData, bearerToken) =>
  API.post('/bills/create', formData, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);

export const getMyBills = (bearerToken) =>
  API.get('/bills/list/by/user', {
    headers: { Authorization: `Bearer ${bearerToken}` }
  }).then((res) => res.data);
