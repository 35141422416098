/** Preauth */
export const APP_LOADED = "appLoaded";
export const TUTORIAL_SKIPPED = "tutorialSkipped";

/** Auth */
export const USER_REGISTERED = "userRegistered";
export const USER_SIGNED_IN = "userSignedIn";
export const USER_PREFERENCE_SAVED = "userPreferenceSaved";
export const USER_TOKEN_REFRESHED = "userTokenRefreshed";
export const ENABLED_BIOMETRICS = "enabledBiometrics";
export const CANCELLED_BIOMETRICS = "cancelledBiometricsRequest";
export const CLEAR_USER_STORE = "clearUserStore";
export const RESET_PWD_EMAIL = "resettingPwdEmail";

/** Groups */
export const SAVE_GROUP_CATEGORY = "saveGroupCategory";
export const OPEN_GROUP = "openGroup";
export const OPEN_BILL = "openBill";
