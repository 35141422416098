import * as actionTypes from "@stores/actionTypes";

const initialAuthenticationState = {
  user: {},
  isRegistered: false,
  isLoggedIn: false,
  session: {},
  hasFaceUnlock: false,
  hasFingerUnlock: false,
  cancelledBiometricsRequest: false,
  biometricsAuthData: {},
  preferences: {},
  pwdReset: { email: null },
};

const AuthenticationReducer = (
  authenticationtionState = initialAuthenticationState,
  action
) => {
  switch (action.type) {
    case actionTypes.USER_REGISTERED:
      return {
        ...authenticationtionState,
        user: action.payload.user,
        isRegistered: action.payload.status,
      };

    case actionTypes.USER_SIGNED_IN:
      return {
        ...authenticationtionState,
        session: action.payload.session,
        isLoggedIn: action.payload.status,
      };

    case actionTypes.USER_PREFERENCE_SAVED:
      return {
        ...authenticationtionState,
        preferences: action.payload.preferences,
      };

    case actionTypes.USER_TOKEN_REFRESHED:
      return {
        ...authenticationtionState,
        session: {
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
          expiresAt: action.payload.expiresAt,
          expiresIn: authenticationtionState.session.expiresIn,
          email: authenticationtionState.session.email,
          password: authenticationtionState.session.password,
        },
      };

    case actionTypes.ENABLED_BIOMETRICS:
      return {
        ...authenticationtionState,
        biometricsAuthData: {
          email: authenticationtionState.session.email,
          password: authenticationtionState.session.password,
        },
        hasFaceUnlock: action.payload.hasFace,
        hasFingerUnlock: action.payload.hasFinger,
      };

    case actionTypes.RESET_PWD_EMAIL:
      return {
        ...authenticationtionState,
        pwdReset: {
          email: action.payload.email,
        },
      };

    case actionTypes.CANCELLED_BIOMETRICS:
      return {
        ...authenticationtionState,
        cancelledBiometricsRequest: action.payload.status,
      };

    case actionTypes.CLEAR_USER_STORE:
      return initialAuthenticationState;

    default:
      return authenticationtionState;
  }
};

export default AuthenticationReducer;
