import * as yup from "yup";

const ResetSchema = yup.object().shape({
  password: yup
    .string()
    .required()
    .label("Password")
    .min(8, "Too short. Use at least 8 characters")
    .max(255, "Try a shorter password."),
  confirm_password: yup
    .string()
    .required()
    .label("Confirm password")
    .test(
      "passwords-match",
      "Passwords must match",
      function checkPassword(value) {
        return this.parent.password === value;
      }
    ),
});

export default ResetSchema;
