import React, { useState } from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "@stores/actions";
import { useHistory } from "react-router-dom";
import theme from "@utils/theme";
import { RequestResetSchema } from "@pages/Schemas";
import { requestResetPwd } from "@api/index";
import { TextInput, Label, AuthButton } from "@components/index";

const RequestResetPage = () => {
  const navigate = useHistory();
  const dispatcher = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: RequestResetSchema,
    onSubmit: (values) => HandleOnCodeRequest(values),
  });

  const [formState, setFormState] = useState({ isLoading: false });
  const [loginError, setLoginError] = useState(null);

  const HandleOnCodeRequest = (values) => {
    setFormState({ ...formState, isLoading: true });
    requestResetPwd(values)
      .then((resp) => {
        setFormState({ ...formState, isLoading: false });
        dispatcher(actions.resettingPwdEmail(values.email));
        navigate.push("/resets/verify-otp");
      })
      .catch((error) => {
        setFormState({ ...formState, isLoading: false });
        setLoginError(`Account Recovery failed. ${String(error)}`);
      });
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FlexRow>
        <FlexColumn top wide={1}>
          <LoginTitle>Reset Password</LoginTitle>
        </FlexColumn>
        <FlexColumnBottom wide={3}>
          <FlexRow>
            <FieldSet>
              <ErrorText>{loginError}</ErrorText>
              <SuccessText>{loginError}</SuccessText>
            </FieldSet>
            <FieldSet>
              <Label title={"Email Address"} input={"email"} />
              <TextInput
                error={formik.errors.email}
                formik={formik}
                formikKey="email"
                type="email"
              />
            </FieldSet>

            <FieldSet>
              <AuthButton
                loading={formState.isLoading}
                disabled={formState.isLoading}
                title={"Request Reset"}
              />
            </FieldSet>

            <FieldSet right>
              <AccountText>
                <AccountLink href="/">{` Back to Homepage `}</AccountLink>
              </AccountText>
            </FieldSet>
          </FlexRow>
        </FlexColumnBottom>
      </FlexRow>
    </Form>
  );
};

const Form = styled.form`
  background-color: ${theme.colors.none};
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 40%;
  }
  padding-bottom: 30px;
  padding-top: 30px;
  border-radius: 30px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FlexColumn = styled.div`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 20px;
  flex: ${(props) => props.wide};
  background-color: ${theme.colors.primary};
`;

const FlexColumnBottom = styled.div`
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 40px;
  flex: ${(props) => props.wide};
  border: solid 1px ${theme.colors.progressBarEmpty};
  background-color: ${theme.colors.white};
  text-align: left;
`;

const FieldSet = styled.div`
  flex: ${(props) => props.wide};
  text-align: ${(props) => (props.right ? "right" : "left")};
`;

const Logo = styled.img`
  margin-top: 20px;
  height: 50px;
  width: auto;
`;
const LoginTitle = styled.p`
  color: ${theme.colors.white};
  font-size: 32px;
  font-weight: bold;
  line-height: 30px;
`;

const AccountText = styled.p`
  color: ${theme.colors.black};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

const AccountLink = styled.a`
  color: ${theme.colors.primary};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

const ErrorText = styled.p`
  color: ${theme.colors.red};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

const SuccessText = styled.p`
  color: ${theme.colors.green};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
`;

export { RequestResetPage };
