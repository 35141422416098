import * as yup from "yup";

const LoginValidationSchema = yup.object().shape({
  email: yup.string().label("Email").email().required().min(5).max(50),
  password: yup
    .string()
    .required()
    .label("Password")
    .min(8, "Password required")
    .max(255, "Try a shorter password."),
});

export default LoginValidationSchema;
