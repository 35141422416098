const STRIPE_PUB_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

let backendService = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENV === "stage") {
  backendService = process.env.REACT_APP_STAGE_API_URL;
}

const SERVER_API = backendService;
const AP_PLAID_FEE = process.env.REACT_APP_AP_PLAID_ONBOARD_FEE;

// console.log("SERVER_API", process.env.REACT_APP_ENV);

export { SERVER_API, STRIPE_PUB_KEY, AP_PLAID_FEE };
